<template>
  <div class="rent-booking-detail animatedBox">
    <div class="container fluid">
      <!-- ========================= BOOKING INFO ========================== -->
      <div class="row mxn-1">
        <div class="col-12 px-1">
          <div class="card p-2 sm-p-3 mb-2">
            <div class="d-flex align-items-center mb-4">
              <fd-button class="circle mr-2" @click="goBack">
                <i class="fas fa-chevron-left"></i>
              </fd-button>
              <h3>Booking Details</h3>
            </div>
            <div class="row">
              <!-- Booking date -->
              <div class="col-12">
                <div class="col-12 row justify-content-between align-items-end">
                  <BookingStatus
                    :status="bookingDetail.status"
                    class="mb-2"
                  ></BookingStatus>
                  <display-data
                    class="text-right"
                    label="Booking Date"
                    :content="
                      $dayjs(bookingDetail.createdAt).format('DD-MM-YYYY')
                    "
                  ></display-data>
                </div>
                <!-- OTR Expired Banner -->
                <div
                  v-if="isOTRExpired && isBookingActive"
                  class="otr-expired col-12 mb-2"
                >
                  OTR is expired, please edit OTR or cancel the booking
                </div>
              </div>

              <!-- Status/Tasks for booking -->
              <div class="col-12 mb-3">
                <div class="row mxn-1">
                  <!-- Signature status -->
                  <div class="col-12 sm-col-6 px-1 mb-3">
                    <div class="bordered-card h-100 p-2">
                      <div class="d-flex align-items-center mb-2">
                        <i class="title-icon fas fa-tasks mr-2"></i>
                        <h5>Checklist</h5>
                      </div>
                      <check-list-status
                        class="mb-1"
                        status="Tenant Signed"
                        :check="bookingDetail.applicantHasSigned"
                      ></check-list-status>
                      <check-list-status
                        class="mb-1"
                        status="Owner Signed"
                        :check="bookingDetail.ownerHasSigned"
                      ></check-list-status>
                      <check-list-status
                        class="mb-1"
                        status="Payment"
                        :check="!!payment"
                      ></check-list-status>
                    </div>
                  </div>
                  <!-- Task status -->
                  <div class="col-12 sm-col-6 px-1 mb-3">
                    <div
                      v-if="bookingDetail.phaseTrackings"
                      class="bordered-card h-100 p-2"
                    >
                      <div class="d-flex align-items-center mb-2">
                        <i class="title-icon fas fa-tasks mr-2"></i>
                        <h5>Task Status</h5>
                      </div>

                      <check-list-status
                        v-for="task in bookingDetail.phaseTrackings[0].tasks"
                        :key="task.id"
                        class="mb-1"
                        :status="task.name"
                        :check="task.isFinish"
                      ></check-list-status>
                    </div>
                  </div>
                </div>
                <!-- Preview OTR -->
                <div class="bordered-card p-2 mb-2">
                  <div class="d-flex align-items-center mb-2">
                    <i class="title-icon far fa-file-alt mr-2"></i>
                    <h5>Offer To Rent</h5>
                  </div>
                  <p
                    v-if="!isOTRExpired && isBookingActive"
                    class="otr-expire-hint mb-2"
                    :class="{ alert: getOTRExpiryDayDiff() < 3 }"
                  >
                    Expire in
                    <span class="font-bold">{{ getOTRExpiryDuration() }}</span>
                  </p>
                  <fd-button
                    v-if="isBookingPhase() && !isBookingCompleted"
                    class="main mr-1"
                    :class="{ bordered: !isOTRExpired }"
                    @click="editOTR"
                    >Edit OTR
                  </fd-button>
                  <fd-button
                    loadingEnabled
                    :isLoading="isPreviewOTRLoading"
                    class="bordered main"
                    @click="previewOTR"
                  >
                    Preview OTR
                  </fd-button>
                  <p
                    v-if="isBookingPhase() && !isBookingCompleted"
                    class="mt-1 fg-grey-700"
                  >
                    You will not be able to edit OTR after confirm booking
                  </p>
                </div>

                <div class="bordered-card p-2">
                  <div class="d-flex align-items-center mb-2">
                    <i class="title-icon far fa-file-alt mr-2"></i>
                    <h5>Tenancy Agreement</h5>
                  </div>
                  <div
                    v-if="
                      isBookingCompleted || isRentCompleted || isTACompleted
                    "
                  >
                    <banner
                      v-if="!isTACompleted"
                      class="bg-grey-100 fg-grey-700 p-2 mb-2"
                      :dismissable="false"
                    >
                      Please complete the tenancy agreement form before complete
                      this rent.
                    </banner>
                    <fd-button
                      v-if="!isRentCompleted"
                      class="main mr-1"
                      :class="{ bordered: !isTACompleted }"
                      @click="editTA"
                      >Edit TA
                    </fd-button>
                    <fd-button
                      v-if="isTACompleted"
                      class="bordered main"
                      loadingEnabled
                      :isLoading="isTADownloading"
                      @click="downloadTA"
                      >Export TA
                    </fd-button>
                  </div>
                  <banner
                    v-else
                    class="bg-grey-100 fg-grey-700 p-2"
                    :dismissable="false"
                  >
                    You can only edit and export TA after completed the booking.
                  </banner>
                </div>
              </div>
            </div>

            <!-- Available Actions -->
            <div v-if="isBookingPhase()" class="row justify-content-end">
              <div v-if="!isOTRExpired">
                <fd-button
                  v-if="isBothPartySigned() && !isBookingCompleted && payment"
                  class="main medium ml-1 my-1"
                  @click="completeBooking"
                  >Complete Booking</fd-button
                >
                <fd-button
                  v-if="isBookingCompleted && isTACompleted"
                  class="main medium ml-1"
                  @click="completeRent"
                >
                  Complete Rent Procedure
                </fd-button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mxn-1">
        <div class="col-12 md-col-8 px-1">
          <div v-if="isBookingPhase()" class="row">
            <div class="card text-right p-2 mb-2">
              <fd-button
                class="danger medium bordered ml-1"
                @click="cancelBooking"
              >
                Cancel Booking
              </fd-button>
            </div>
          </div>

          <!-- ================== TENANT & OWNER DOCUMENTS ================== -->
          <div class="card p-2 md-p-3 mb-2">
            <h4 class="mb-3">Submitted Documents</h4>
            <div
              v-if="isBookingPhase() && !isOTRExpired"
              class="row justify-content-end mb-3"
            >
              <fd-button
                v-if="canRequestTenantResubmit()"
                class="main bordered ml-1 my-1"
                @click="requestTenantResubmit"
              >
                <i class="fas fa-rotate-right mr-1"></i>
                Request Tenant Resubmit
              </fd-button>
              <fd-button
                v-if="canRequestVendorResubmit()"
                class="main bordered ml-1 my-1"
                @click="requestOwnerResubmit"
              >
                <i class="fas fa-rotate-right mr-1"></i>
                Request Owner Resubmit
              </fd-button>
            </div>
            <Tab v-model="submissionTab" :tabs="submissionTabs"></Tab>

            <submission-documents
              class="mt-3"
              :role="submissionTab == 'OWNER' ? 'Owner' : 'Tenant'"
              :submission="
                submissionTab == 'OWNER'
                  ? bookingDetail.ownerSubmission
                  : bookingDetail.applicantSubmission
              "
            ></submission-documents>
          </div>
          <!-- ======================= TRACKING LOG ======================== -->
          <tracking-table
            v-if="trackingLogs"
            class="tracking card p-2 sm-p-3 w-100 mb-2"
            :data="trackingLogs"
            :isLoading="isTrackingLogLoading"
            :loadingCount="2"
          >
            <template #content="{ row }">
              <h6 class="font-bold mb-1">{{ row.title }}</h6>
              <p>{{ $dayjs(row.updatedAt).format("DD-MM-YYYY") }}</p>
              <p>{{ row.agentContent }}</p>
            </template>
          </tracking-table>
        </div>

        <div class="col-12 md-col-4 px-1">
          <!-- ======================= PAYMENT INFO ====================== -->
          <div class="card p-2 sm-p-3 mb-2">
            <h3 class="mb-4">Payment</h3>
            <div v-if="!_.isEmpty(payment)">
              <p class="payment-amount mb-1">
                RM{{ numberWithCommas(payment.paymentAmount) }}
              </p>
              <display-data
                label="Payment Method"
                :content="payment.paymentMethod"
              ></display-data>
              <display-data
                v-if="payment.paymentMethod == paymentMethod.CHEQUE"
                label="Cheque Number"
                :content="payment.chequeNumber"
              ></display-data>
              <display-data
                v-if="payment.paymentMethod == paymentMethod.ONLINE_BANKING"
                label="Transaction ID"
                :content="payment.transactionId"
              ></display-data>
              <display-data
                label="Paid At"
                :content="$moment(payment.paidAt).format('DD-MM-YYYY')"
              ></display-data>
              <download-button
                v-if="payment.paymentMethod == paymentMethod.CASH_DEPOSIT"
                label="Deposit Receipt"
                :filenamePrefix="`deposit_receipt_${payment.id}`"
                :downloadItem="payment.depositReceipt"
                @download="downloadReceipt"
              ></download-button>
            </div>
            <!--  -->
            <div v-else>No payment has been made yet.</div>
          </div>
          <!-- ======================= PROPERTY INFO ======================= -->
          <div class="card mb-2">
            <div v-if="!_.isEmpty(property)" class="row">
              <div
                class="property-main-photo"
                :style="{
                  'background-image': `linear-gradient(to bottom, #00000010, #000000EE),
                  url(${
                    property.mainPhoto.length > 0
                      ? $getFileURL(property.mainPhoto[0])
                      : require(`@/assets/image/placeholder/no-img.png`)
                  })`
                }"
              >
                <div class="d-flex align-items-end h-100 p-2">
                  <div>
                    <h5 class="fg-white mb-1">{{ property.title }}</h5>
                    <p class="fg-white">{{ property.referenceCode }}</p>
                  </div>
                </div>
              </div>
              <div class="p-2 sm-p-3">
                <div class="d-flex align-items-start mb-1">
                  <i class="fas fa-map-marker-alt mr-1"></i>
                  <p style="line-height: 1">
                    {{ getPropertyAddress(property) }}
                  </p>
                </div>
                <div class="d-flex align-items-start mb-1">
                  <i class="fas fa-chart-area mr-1"></i>
                  <p style="line-height: 1">
                    {{ property.landArea }}/{{ property.areaUnitType }}
                  </p>
                </div>
                <div class="d-flex align-items-start mb-1">
                  <i class="fas fa-layer-group mr-1"></i>
                  <p style="line-height: 1">
                    {{ property.builtUpArea }}/{{ property.areaUnitType }}
                  </p>
                </div>
                <p class="rental-fee">
                  RM
                  {{
                    numberWithCommas(
                      parseInt(bookingDetail.offerToRent.rentPrice)
                    )
                  }}/month
                </p>
              </div>
            </div>
          </div>
          <!-- ========================= TENANT INFO ======================== -->
          <div class="card p-2 sm-p-3 mb-2">
            <h3 class="mb-4">Tenant</h3>
            <div v-if="!_.isEmpty(tenant)" class="d-flex">
              <avatar
                :src="getTenantAvatar()"
                class="mr-2"
                :name="tenant.name"
              ></avatar>
              <div>
                <h5 class="mb-1">{{ tenant.name }}</h5>
                <p>{{ $getUserPhoneNumber(tenant.user) }}</p>
                <p>{{ tenant.email }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ========================= MODAL: OTR FORM ========================= -->
    <modal v-model="editOTRModal.isShown" fullscreen>
      <fd-form class="card modal-content-wrapper" @submit="updateOTR">
        <modal-body class="p-2 sm-p-3">
          <h3 class="mb-4">Offer To Rent</h3>
          <otr-form
            isEdit
            showPropertyType
            :isLoading="editOTRModal.isLoading"
            :otrData="editOTRModal.otrData"
            @change="
              (data) => {
                this.editOTRModal.otrData = data;
              }
            "
            required
          ></otr-form>
        </modal-body>
        <modal-footer class="row justify-content-end p-2">
          <fd-button class="medium mr-1" @click="editOTRModal.isShown = false">
            Cancel
          </fd-button>
          <fd-button
            loadingEnabled
            :isLoading="editOTRModal.isLoading"
            :disabled="editOTRModal.isLoading"
            type="submit"
            class="main medium"
            >Save</fd-button
          >
        </modal-footer>
      </fd-form>
    </modal>
    <!-- ========================= MODAL: TA FORM ========================= -->
    <modal v-model="editTAModal.isShown" fullscreen>
      <div class="card modal-content-wrapper">
        <modal-body class="p-2 sm-p-3">
          <h3 class="mb-4">Edit Tenancy Agreement</h3>
          <ta-form
            ref="taForm"
            isEdit
            :stickyOffset="-20"
            :taGeneral.sync="taGeneralData"
            :taTenant.sync="taTenantData"
            :taLandlord.sync="taLandlordData"
            :isLoading.sync="editTAModal.isLoading"
            required
            @submit="updateTA"
          ></ta-form>
        </modal-body>
        <modal-footer class="row justify-content-end p-2">
          <fd-button class="medium mr-1" @click="editTAModal.isShown = false">
            Close
          </fd-button>
          <fd-button
            class="medium main mr-1"
            @click="() => this.$refs.taForm.submitAll()"
          >
            Update
          </fd-button>
        </modal-footer>
      </div>
    </modal>
  </div>
</template>

<script>
import {
  required,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import { rentBooking as rentAPI } from "@/api";
import { downloadFile } from "@/utils/download";
import { getPropertyAddress } from "@/utils/property";
import { numberWithCommas, getFileExtension } from "@/utils/string";
import { getDateDiff } from "@/utils/datetime";
import {
  subsalesPaymentMethod as paymentMethod,
  rentBookingStatus
} from "@/enums";
import { RentOTRModel, RentTAModel } from "@/models";

export default {
  name: "booking-detail",
  components: {
    Avatar: () => import("@/components/GlobalComponents/Avatar"),
    Tab: () => import("@/components/GlobalComponents/FdTabs/TabsIso"),
    TrackingTable: () => import("@/components/GlobalComponents/TrackingTable"),
    DisplayData: () => import("@/components/GlobalComponents/DisplayData"),
    Banner: () => import("@/components/GlobalComponents/Banner"),
    CheckListStatus: () =>
      import("@/components/GlobalComponents/List/CheckListStatus"),
    SubmissionDocuments: () =>
      import("@/components/SubsalesBooking/SubmissionDocuments"),
    DownloadButton: () =>
      import(
        "@/components/GlobalComponents/Shared/Banker/BankLoanDownloadButton"
      ),
    BookingStatus: () => import("@/components/RentBooking/BookingStatus"),
    OtrForm: () => import("@/components/GlobalComponents/Shared/Rent/OTRForm"),
    TaForm: () => import("@/modules/Rent/components/TAForm")
  },
  data() {
    return {
      bookingDetail: {
        status: {
          id: ""
        }
      },
      property: {},
      tenant: {},
      payment: {},
      taData: null,
      taGeneralData: {},
      taTenantData: {},
      taLandlordData: {},

      isTrackingLogLoading: false,
      trackingLogs: [],

      submissionTab: "TENANT",
      submissionTabs: [
        { label: "Tenant", name: "TENANT" },
        { label: "Owner", name: "OWNER" }
      ],

      isPreviewOTRLoading: false,
      isTADownloading: false,

      editOTRModal: {
        isShown: false,
        isLoading: false,
        otrData: {}
      },
      editTAModal: {
        isShown: false,
        isLoading: false
      },

      // Validator
      validators: {
        required: required,
        price: price
      },

      // Utils & Enums
      paymentMethod,
      rentBookingStatus,
      getPropertyAddress,
      numberWithCommas,
      RentTAModel
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    isOTRExpired() {
      let dayDiff = this.getOTRExpiryDayDiff();
      return dayDiff <= 0;
    },
    isBookingCompleted() {
      return this.bookingDetail.status.id == rentBookingStatus.OTR_COMPLETED;
    },
    isTACompleted() {
      return this.taData?.isCompleteDetail === true;
    },
    isRentCompleted() {
      return this.bookingDetail.status.id == rentBookingStatus.RENT_COMPLETED;
    },
    isBookingActive() {
      let booking = this.bookingDetail;
      return (
        booking.status.id !== rentBookingStatus.OTR_COMPLETED &&
        booking.status.id !== rentBookingStatus.RENT_CANCELLED &&
        booking.status.id !== rentBookingStatus.RENT_COMPLETED
      );
    }
  },
  methods: {
    async init() {
      await Promise.all([this.getBooking(), this.getTrackingLogs()]);
      if (this.isBookingCompleted || this.isRentCompleted) {
        await this.getTAData();
      }
    },
    goBack() {
      this.$router.replace({ name: "RentBookingList" });
    },
    getTenantAvatar() {
      return this.tenant.avatar.length > 0
        ? this.$getFileURL(this.tenant?.avatar[0])
        : null;
    },
    isBookingPhase() {
      return this.bookingDetail.status.id == rentBookingStatus.OTR_IN_PROGRESS;
    },

    isBothPartySigned() {
      return (
        this.bookingDetail.applicantHasSigned &&
        this.bookingDetail.ownerHasSigned
      );
    },
    canRequestTenantResubmit() {
      return !this.isBookingCompleted && this.bookingDetail.applicantHasSigned;
    },
    canRequestVendorResubmit() {
      return !this.isBookingCompleted && this.bookingDetail.ownerHasSigned;
    },
    getOTRExpiryDuration() {
      let expiryDate = this.$dayjs(
        this.bookingDetail.confirmOTRBefore,
        "YYYY-MM-DD"
      );
      return getDateDiff(expiryDate, { full: true, space: true });
    },
    getOTRExpiryDayDiff() {
      let today = this.$dayjs();
      let expiryDate = this.$dayjs(
        this.bookingDetail.confirmOTRBefore,
        "YYYY-MM-DD"
      );
      return expiryDate.diff(today, "days", true);
    },
    async editOTR() {
      // Get OTR Data
      await this.getOTRData();
      // Open OTR Modal
      this.editOTRModal.isShown = true;
    },
    async editTA() {
      await this.getTAData();
      // Open OTR Modal
      this.editTAModal.isShown = true;
    },
    // ============================== API RELATED ==============================
    async getBooking() {
      this.$store.commit("setIsLoading", true);
      try {
        let data = await rentAPI.getBooking(this.$route.params.id);

        this.bookingDetail = data;
        this.property = this._.cloneDeep(data.property);
        this.tenant = this._.cloneDeep(data.tenant);
        this.payment = this._.cloneDeep(data.payment);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Failed",
          text: "Failed to get booking data. Please try again later."
        });
        throw error;
      }
    },
    async getTrackingLogs() {
      try {
        this.isTrackingLogLoading = true;
        let data = await rentAPI.getTrackingLogs(this.$route.params.id);
        this.trackingLogs = data;
        this.isTrackingLogLoading = false;
      } catch (error) {
        this.isTrackingLogLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch tracking log data, . Please try again later."
        });
      }
    },
    async downloadReceipt(file, label, loading) {
      loading(true);
      let url = this.$getFileURL(file);
      let ext = getFileExtension(file.fileName);
      try {
        await downloadFile(url, label, ext);
        loading(false);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Download failed",
          text: "Failed to download the receipt. Please try again later."
        });
        loading(false);
      }
    },
    async cancelBooking() {
      let confirm = await this.$confirm({
        type: "alert",
        title: "Cancel Booking",
        message:
          "Are you sure you want to cancel this booking? This action cannot be undone.",
        confirmText: "Yes, Cancel Booking",
        cancelText: "No, Continue This Booking"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await rentAPI.cancelPurchase(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Booking Cancelled",
            text: "Booking has been cancelled successfully."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to cancel this booking. Please try again later."
          });
        }
      }
    },

    async getOTRData() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await rentAPI.getOTRData(this.$route.params.id);
        this.editOTRModal.otrData = this._.cloneDeep(
          RentOTRModel.getToEdit(data)
        );

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to load OTR data. Please try again later."
        });
      }
    },
    async previewOTR() {
      this.isPreviewOTRLoading = true;
      await rentAPI.previewOTR(this.$route.params.id);
      this.isPreviewOTRLoading = false;
    },
    async updateOTR() {
      try {
        this.$store.commit("setIsLoading", true);
        await rentAPI.updateOTR(
          this.$route.params.id,
          RentOTRModel.putPayload(this.editOTRModal.otrData)
        );
        await this.getBooking();
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "success",
          title: "OTR Updated",
          text: "Offer to Rent has been updated successfully."
        });
        this.editOTRModal.isShown = false;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update OTR form. Please try again later."
        });
      }
    },
    async getTAData() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await rentAPI.getTenancyAgreement(this.$route.params.id);
        this.taData = this._.cloneDeep(data);

        this.taGeneralData = RentTAModel.getToEditTA(data);
        this.taTenantData = RentTAModel.getToEditTAApplicant(data);
        this.taLandlordData = RentTAModel.getToEditTAOwner(data);

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get tenancy agreement data. Please try again later."
        });
      }
    },
    async downloadTA() {
      try {
        this.isTADownloading = true;
        await rentAPI.downloadTenancyAgreement(this.$route.params.id);
        this.isTADownloading = false;
      } catch (error) {
        this.isTADownloading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to export tenancy agreement. Please try again later."
        });
      }
    },
    async updateTA(payload) {
      try {
        this.$store.commit("setIsLoading", true);
        let parsedPayload = RentTAModel.toUpdateTA(payload);
        await rentAPI.updateTenancyAgreement(
          this.$route.params.id,
          parsedPayload
        );

        this.$notify({
          group: "alert",
          type: "success",
          title: "TA Updated",
          text: "Tenancy Agreement has been updated successfully."
        });

        this.getTAData();

        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update TA form. Please try again later."
        });
      }
    },
    async requestTenantResubmit() {
      let confirm = await this.$confirm({
        title: "Request Tenant Resubmit",
        message: "Are you sure you want to request a resubmit for tenant?",
        confirmText: "Confirm Request"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await rentAPI.requestTenantResubmit(this.$route.params.id);
          await this.getBooking();
          this.$store.commit("setIsLoading", false);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Something went wrong while requesting for tenant resubmit. Please try again later."
          });
        }
      }
    },
    async requestOwnerResubmit() {
      let confirm = await this.$confirm({
        title: "Request Owner Resubmit",
        message: "Are you sure you want to request a resubmit for owner?",
        confirmText: "Confirm Request"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await rentAPI.requestOwnerResubmit(this.$route.params.id);
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Something went wrong while requesting for owner resubmit. Please try again later."
          });
        }
      }
    },
    async completeBooking() {
      let confirm = await this.$confirm({
        title: "Complete Booking",
        message: `Are you sure you want to complete the booking?
          <br>
          You will no longer be able to edit OTR Form after this.`
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await rentAPI.completeBooking(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Booking has been mark as completed."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: "Failed to complete booking. Please try again later."
          });
        }
      }
    },
    async completeRent() {
      let confirm = await this.$confirm({
        title: "Complete Rent Procedure",
        message: "Are you sure you want to complete the rent procedure?",
        confirmText: "Complete"
      });

      if (confirm) {
        try {
          this.$store.commit("setIsLoading", true);
          await rentAPI.completePurchase(this.$route.params.id);
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: "Rent procedure has been mark as completed."
          });
          this.$store.commit("setIsLoading", false);
          await Promise.all([this.getBooking(), this.getTrackingLogs()]);
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text:
              "Failed to complete the rent procedure. Please try again later."
          });
        }
      }
    }
  }
};
</script>

<style lang="scss">
.rent-booking-detail {
  .property-main-photo {
    width: 100%;
    height: 200px;
    background-size: cover;
    color: white;
    background-position: center;
  }

  i.title-icon {
    font-size: 24px;
  }

  .rental-fee {
    font-size: 18px;
    font-weight: bold;
    color: $color-secondary;
  }

  .otr-expired {
    background: $color-danger;
    color: white;
    border-radius: 4px;
    padding: 16px 32px;
  }
  .otr-expire-hint {
    font-size: 16px;
    &.alert {
      color: $color-danger;
    }
  }

  .tracking {
    h6 {
      color: #404040;
    }
  }
  .payment-amount {
    font-size: 24px;
    font-weight: bold;
    color: $color-secondary;
  }
}
</style>
