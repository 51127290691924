import * as dayjs from "dayjs";

const UNIT = {
    SECOND: {
        short: "s",
        full: "second",
        fullPlural: "seconds"
    },
    MINUTE: {
        short: "m",
        full: "minute",
        fullPlural: "minutes"
    },
    HOUR: {
        short: "h",
        full: "hour",
        fullPlural: "hours"
    },
    DAY: {
        short: "d",
        full: "day",
        fullPlural: "days"
    },
    WEEK: {
        short: "w",
        full: "week",
        fullPlural: "weeks"
    },
    MONTH: {
        short: "mon",
        full: "month",
        fullPlural: "months"
    },
    YEAR: {
        short: "y",
        full: "year",
        fullPlural: "years"
    }
};

const DEFAULT_DATE_DIFF_OPTION = { full: false, space: false, absolute: true };

function getTimeDiffString(timeDiff, UNIT, { full, space }) {
    function getSpacing() {
        return space ? " " : "";
    }

    let unit = UNIT.short;
    if (full) {
        unit = UNIT.full;
        if (timeDiff > 1) {
            unit = UNIT.fullPlural;
        }
    }
    return `${timeDiff}${getSpacing()}${unit}`;
}

export function getDateDiff(
    date,
    {
        full = DEFAULT_DATE_DIFF_OPTION.full,
        space = DEFAULT_DATE_DIFF_OPTION.space,
        absolute = DEFAULT_DATE_DIFF_OPTION.absolute
    } = {}
) {
    let dateToCalculate = dayjs(date);
    let option = { full, space, absolute };

    const MINUTE = 60;
    const HOUR = MINUTE * 60;
    const DAY = HOUR * 24;
    const WEEK = DAY * 7;
    const MONTH = WEEK * 4;
    const YEAR = MONTH * 12;

    let result;
    let secsDiff = dayjs().diff(dateToCalculate, "second", true);

    // Converts to positive
    if (absolute) secsDiff = Math.abs(secsDiff);

    if (secsDiff < MINUTE) {
        let diff = secsDiff;
        result = getTimeDiffString(parseInt(diff), UNIT.SECOND, option);
    }
    if (secsDiff >= MINUTE) {
        let diff = secsDiff / MINUTE;
        result = getTimeDiffString(parseInt(diff), UNIT.MINUTE, option);
    }
    if (secsDiff >= HOUR) {
        let diff = secsDiff / HOUR;
        result = getTimeDiffString(parseInt(diff), UNIT.HOUR, option);
    }
    if (secsDiff >= DAY) {
        let diff = secsDiff / DAY;
        result = getTimeDiffString(parseInt(diff), UNIT.DAY, option);
    }
    if (secsDiff >= WEEK) {
        let diff = secsDiff / WEEK;
        result = getTimeDiffString(parseInt(diff), UNIT.WEEK, option);
    }
    if (secsDiff >= MONTH) {
        let diff = secsDiff / MONTH;
        result = getTimeDiffString(parseInt(diff), UNIT.MONTH, option);
    }
    if (secsDiff >= YEAR) {
        let diff = secsDiff / YEAR;
        result = getTimeDiffString(parseInt(diff), UNIT.YEAR, option);
    }
    return result;
}
